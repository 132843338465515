import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import LinkText from '../components/Blocks/LinkText/linkText';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../components/Blocks/PlaceImage/placeImage';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../components/SEO/seo';

const UnionPage = () => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );
    
    return (
    <Layout >
       <SEO lang="en" title={'Design of Union'} keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'юзабилити', 'usability-testing', 'cart-sorting']} description="UI/UX application design for finding and implementing ideas. We use the best UX practices to make the product more user-friendly.">
        </SEO>

        <HeroSection animationType="blobby" heading="How to make an innovative product even more innovative?" desc="Design of a service for the implementation of ideas - Union."/>

        <Section heading="Project">
            <Paragraph>Union is a service for finding people, projects and teams. He will help you find like-minded people and implement your idea as soon as possible. Even if you are not a generator of ideas, you may well find yourself an interesting project or startup.</Paragraph>
            <Paragraph>I quickly became interested in the project and realized that I wanted to help with the design for it.</Paragraph>
        </Section>

        <PhotoLeftTextRight salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Prototypes" fileURL="union-wireframes.png" alt="Prototypes">
            <Paragraph>The first thing to do was create simple prototypes in order to quickly tweak the design and not think too much about beauty.</Paragraph>
            <Paragraph>Here we discussed, trying to display the ideas that we had.</Paragraph>
        </PhotoLeftTextRight>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Usability-testing">
            <Paragraph>To make the service more accessible and more user-friendly, we conducted usability testing to determine what problems the user may face when we give him a task.</Paragraph>
            <Paragraph>Thanks to usability testing, we realized that the names of the categories are not very clear and it is not always clear where the project is and where the team is.</Paragraph>
            <Paragraph>After usability testing, you need to rewrite the problems, add them as tasks, and fix the design.</Paragraph>
            <Paragraph>By the way, I recommend <LinkText href="https://www.notion.so/Usability-testing-716470724d4f46b290b3dbbefcb5d36b">this template for usability-testing in Notion</LinkText> it will help you keep track of all appointments and save data. I also recommend the books "Don't make me think" and "Rocket surgery made easy", in which Steve Krug tells in an accessible way how to successfully conduct usability-testing.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Cart-sorting">
            <Paragraph>As I already wrote, we had problems with categories. To solve such problems, smart people came up with cart sorting. We wrote categories on stickers and gave cards to people so that they would categorize them.</Paragraph>
            <Paragraph>With this technique, we identified categories that were difficult to understand.</Paragraph>
            <Paragraph>Usability testing is not difficult to understand and accept. But card-sorting raised doubts among some team members - "But the sample is not large" and "This is not statistically significant." Yes, indeed - the sample is small. But we use this method not to prove something, but in order to determine what problems any person may have. And if they arise, then they can arise and another person, because we do not want to make a product only for a certain caste of people?</Paragraph>
            <PlaceImage filename="union-cardsorting.png" alt="One of card-sorting results"/>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Screen map">
            <Paragraph>In order not to forget anything, not a screen, not a little functionality. We have connected all screens to visually show the user's path.</Paragraph>
            <Paragraph>When all this was done there was no such great figma plugin like "Autoflow". He would greatly simplify our work, click on one screen, then on the second and everything is connected.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="union-screenmap.png" alt="Screen Map"/>

        <PhotoLeftTextRight salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Component library" fileURL="union-designsystem.png" alt="Design System">
            <Paragraph>In order to make our design more flexible, we made a design system. Some may call it useless, but for such a large project it is a necessity. When the design of the system is flexible, you can change one component and others will follow - those on which it depends.</Paragraph>
            <Paragraph>This design system has helped and will help designers.</Paragraph>
        </PhotoLeftTextRight>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Final Designs">
            <Paragraph>Now you can start creating layouts. When you have a good library of components, you can imagine playing Lego, quickly pulling components and assembling layouts.</Paragraph>
            <Paragraph>At this stage, it is important to conveniently put layouts on pages, so that it is convenient for designers, product managers, and developers alike.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="union-final.png" alt="Final Designs"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Results">
            <Paragraph>As a result, we got layouts ready for development and subsequent iterations, and a ready-made design system will help make edits much faster.</Paragraph>
        </Section>

        <PerformanceSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" performances={[{ id: 1, metric: '8 mos', label: 'Of Work' }, { id: 1, metric: '+80', label: 'Screens' }, { id: 1, metric: '1', label: 'Kick-ass Design System' }]}/>

    </Layout>
)};

export default UnionPage;
